<template>
  <div class="relative">
    <div class="flex justify-end" style="margin-bottom: -35px">
      <md-button
        class="bg-victoria text-white rounded"
        @click="onCreateNew"
      >Create New</md-button>
    </div>
    <Tab :items="items">
      <template v-slot:icon="{ item }">
        <md-icon class="m-0">{{ item.icon }}</md-icon>
      </template>
    </Tab>
    <router-view @on-click="onClick" />
    <!-- Dialog Start -->
    <Dialog >
      <components :content="content" :is="component"></components>
    </Dialog>
    <!-- Dialog Start -->
  </div>
</template>

<script>
import Tab from "@/components/atom/Tab";
import { Dialog } from "@/components";
import CreateStudyPlan from "@/components/molecule/study-plan/CreateStudyPlan";
import ShowDetails from "@/components/molecule/study-plan/ShowDetails";
import { mapMutations } from "vuex";

export default {
  name: "TabRouter",
  components: {
    Tab,
    Dialog,
    ShowDetails,
    CreateStudyPlan,
  },
  data() {
    return {
      component: 'CreateStudyPlan',
      content: {},
      items: [
        {
          name: "Study Plan Acheives",
          path: "studyPlan.achieves",
          icon: "date_range",
        },
        { name: "Study Plan List", path: "studyPlan.list", icon: "list" },
      ],
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onCreateNew() {
      this.component = 'CreateStudyPlan';
      this.dialog(true);
    },
    onClick(item) {
      this.component = 'ShowDetails';
      this.content = item;
      this.dialog(true);
    }
  },
};
</script>
