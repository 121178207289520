<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" label="7 Days Plan" align="center" />
      
        <Content class="my-3" label="Study Plan ID" text="SP125" />
        <Content class="my-3" label="Duration" text="7 Days" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
            <Content class="my-3" label="Branch" text="Dhanmondi" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Question Category" text="Exp. Listening" />
            </div>
        </div>
        <template v-for="(item, index) in items">
            <OutlineCollapse
                @on-click="onToggle(index)"
                :title="item.title"
                :active="currentIndex == index"
                :key="index"
            >
            
            <Content class="my-3" label="Tasks" text="Lession 01 Chapter 2" />
             <div class="my-4">
                <p class="text-victoria text-uppercase">Related Materials</p>
                <p class="font-bold text-lg line-height-27">
                    <span class="text-uppercase mr-3">Materials:</span><a href="" class="text-victoria text-lowarcase">https://apical123458</a>
                </p>
            </div>
            
             <div class="my-4">
                <p class="text-victoria text-uppercase">Home- Work</p>
                <h2 class="my-2">Homework:</h2>
                <p class="font-bold text-lg line-height-27">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit ducimus fugit id nobis laudantium ex?
                </p>
            </div>
            </OutlineCollapse>
        </template>
        <Content class="my-10" label="Status" text="Live" text-color="text-green" />
        <div class="flex justify-between align-center">
            <div>
                <div class="bg-default text-uppercase rounded w-10 center mr-1 py-1 pointer">
                    <md-icon class="m-0">keyboard_arrow_left</md-icon>
                </div>
                <div class="bg-default text-white text-uppercase rounded w-10 center ml-1 py-1 pointer">
                    <md-icon class="m-0">keyboard_arrow_right</md-icon>
                </div>
            </div>
            <div>
                <md-button class="bg-default text-uppercase rounded">Back</md-button>
                <md-button class="bg-victoria text-white text-uppercase rounded">Go to edit page</md-button>
            </div>
            <div></div>
        </div>
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import OutlineCollapse from "@/components/atom/OutlineCollapse";
export default {
  components: {
    OutlineCollapse,
    DialogTitle,
    Content
  },
  data() {
      return {
          currentIndex: 0,
          items: [
              {id: 1, title: 'Day 01'},
              {id: 2, title: 'Day 02'},
              {id: 3, title: 'Day 03'},
              {id: 4, title: 'Day 04'}
          ]
      }
  },
  methods: {
      onToggle(index) {
          this.currentIndex = index
      }
  }
}
</script>