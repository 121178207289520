<template>
  <form @submit.prevent="onSubmit" class="p-6">
    <DialogTitle bg-color="bg-victoria" label="Create New Study Plan" align="center" />
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-75">
        <RadioButtonComponent
          label="Plan Type"
          active-class="font-semibold"
          :items="[{id:1, label: 'Course Wise'}, {id:2, label: 'Student Wise'}]"
        />
      </div>

      <div class="md-layout-item md-size-60 mb-3 my-10">
        <SelectComponent
          label="Choose Package Name"
          italic
          class="w-full"
          v-model.trim="$v.form.packageName.$model"
          :message="!$v.form.packageName.required && $v.form.packageName.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-60 mb-3">
        <InputFieldComponent
          label="Name Of Plan"
          italic
          v-model.trim="$v.form.planName.$model"
          :message="!$v.form.planName.required && $v.form.planName.$dirty ? 'Field is required' : null"
        />
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-30 mb-3">
        <SelectComponent 
          label="Select Branch"
          italic
          class="w-full"
          v-model.trim="$v.form.branchId.$model"
          :message="!$v.form.branchId.required && $v.form.branchId.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-30 mb-3">
        <SelectComponent
          label="Select course"
          italic
          class="w-full"
          v-model.trim="$v.form.courseId.$model"
          :message="!$v.form.courseId.required && $v.form.courseId.$dirty ? 'Field is required' : null"
        />
      </div>
      <div class="md-layout-item md-size-100 mb-3">
        <template v-for="(item, index) in items">
            <OutlineCollapse
                @on-click="onToggle(index)"
                :title="item.title"
                :active="currentIndex == index"
                :key="index"
            >
            <div class="md-layout md-gutter flex align-center my-2">
              <div class="md-layout-item md-size-25">
                <p class="text-uppercase font-bold text-lg">Task Name</p>
              </div>
              <div class="md-layout-item md-layout md-gutter md-size-75">
                <div class="md-layout-item">
                  <InputFieldComponent />
                </div>
              </div>
            </div>
            <div class="md-layout md-gutter flex align-center my-2">
              <div class="md-layout-item md-size-25">
                <p class="text-uppercase font-bold text-lg">Related Material</p>
              </div>
              <div class="md-layout-item md-gutter md-layout md-size-75">
                <div class="md-layout-item">
                  <InputFieldComponent
                    placeholder="Material Name"
                  />
                </div>
                <div class="md-layout-item">
                  <InputFieldComponent
                    placeholder="Material Link"
                  />
                </div>
              </div>
            </div>
            <div class="md-layout md-gutter flex align-center my-2">
              <div class="md-layout-item md-size-25">
                <p class="text-uppercase font-bold text-lg">Home Work</p>
              </div>
              <div class="md-layout-item md-gutter md-layout md-size-75">
                <div class="md-layout-item">
                  <InputFieldComponent placeholder="Home Work Name" />
                </div>
                <div class="md-layout-item">
                  <InputFieldComponent placeholder="Home Work Details" />
                </div>
              </div>
            </div>

            </OutlineCollapse>
        </template>
      </div>
      <div
        class="flex justify-end align-center md-layout-item md-size-100 mb-3"
      >
        <md-button class="md-default">Save As Draft</md-button>
        <md-button class="border border-solid border-gray-500 rounded"
          >Schedule</md-button
        >
        <md-button type="submit" class="bg-victoria text-white rounded">Create</md-button>
      </div>
    </div>
  </form>
</template>

<script>
import { SelectComponent, InputFieldComponent, RadioButtonComponent, DialogTitle } from "@/components";
import OutlineCollapse from "@/components/atom/OutlineCollapse";
import { required } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    SelectComponent,
    OutlineCollapse,
    InputFieldComponent,
    RadioButtonComponent
  },
  data() {
    return {
      radio: true,
      form: {
        packageName: "",
        planName: "",
        branchId: "",
        courseId: "",
      },
      currentIndex: 0,
      items: [
          {id: 1, title: 'Day 01'},
          {id: 2, title: 'Day 02'},
          {id: 3, title: 'Day 03'},
          {id: 4, title: 'Day 04'}
      ]
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    onSubmit() {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }
      this.setShowSnackbar('Study plan saved successfully !');
      setTimeout(() => {
       this.dialog(false);
      }, 1000);
    },
    onToggle(index) {
        this.currentIndex = index
    }
  },
  validations: {
    form: {
      packageName: {required},
      planName: {required},
      branchId: {required},
      courseId: {required}
    }
  }
};
</script>

<style></style>
